import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import AboutMain from "../images/about/about-main.jpg";

import { Route, Routes, useParams } from 'react-router-dom';
function Trips() {
  const trips=[
    {
      id:1,
      trip:'Mumbai To Kolhapur',
      imageUrl:"/city/kolhapur.png",
      description:"Kolhapur, a historic city in Maharashtra, is famous for its rich cultural heritage, magnificent temples, and iconic local cuisine. Whether you are a history enthusiast, a devotee, or simply a traveler seeking to explore the region's beauty, Kolhapur offers a diverse range of experiences.",
      data:[
        {
          title:'Best Time to Visit',
          description:'Best Time to Visit',
          objData:[
          {
            key:'October to March (Winter)',
            value:'This is the best time to visit Kolhapur when the weather is pleasant and suitable for sightseeing.',
          },{
            key:'Monsoon (June to September)',
            value:"The city becomes lush with greenery, especially in the surrounding areas. While it's beautiful, be prepared for heavy rainfall.",
          },{
            key:'Summer (April to June)',
            value:"Summers can be quite hot, so it's not an ideal time for sightseeing",
          }]
        },
        {
          title:'Attractions in Kolhapur',
          description:'Best Time to Visit Kolhapur',
          objData:[
          {
            key:'Mahalakshmi Temple',
            value:"One of the most famous temples in India, dedicated to Goddess Mahalakshmi. Its a significant pilgrimage site and a marvel of ancient architecture.",
          },{
            key:"Shahu Maharaj Palace",
            value:"This beautiful palace is a blend of Indo-Saracenic architectural styles and serves as a museum showcasing the artifacts of the Kolhapur royal family.",
          },
          {
            key:"Rankala Lake",
            value:"A serene spot perfect for evening strolls and boating. The scenic beauty of the lake, combined with nearby gardens, makes it a great place to relax.",
          },
          {
            key:"Jyotiba Temple",
            value:"Situated on a hilltop, this temple is dedicated to Lord Jyotiba, a powerful deity. The temple offers stunning views of the surrounding countryside.",
          },
          {
            key:"Panhala Fort",
            value:"A historic fort offering panoramic views and a glimpse into Maratha history, especially the reign of Chhatrapati Shivaji Maharaj.",
          }]
        }
      ]
    },
    {
      id:2,
      trip:'Trip To Mumbai',
      imageUrl:"/city/mumbai.jpg",
      description:"Mumbai, often called the 'City of Dreams' offers a blend of modernity, history, and vibrant culture. As the financial capital of India, Mumbai is a bustling metropolis with something for everyone—from iconic landmarks to culinary delights, from cultural experiences to shopping extravaganzas.",
      data:[
        {
          title:'Best Time to Visit',
          description:'The best time to visit Mumbai is between October and March when the weather is cooler and more pleasant. Avoid the monsoon season (June to September) as the city experiences heavy rains.',
          objData:[]
        },{
          title:'Best in Mumbai',
          description:'Best Places to visit in mumbai',
          objData:[
          {
            key:'Gateway of India',
            value:"An iconic monument, built during the British Raj, located by the Arabian Sea. It's a popular tourist spot and a great place to start your Mumbai exploration. You can also take ferry rides to nearby Elephanta Caves from here.",
          },
          {
            key:"Siddhivinayak Temple",
            value:"One of Mumbai's most revered temples, dedicated to Lord Ganesha, it attracts thousands of devotees and tourists alike.",
          },{
            key:"Marine Drive",
            value:"Known as the “Queen's Necklace,” Marine Drive is a 3.6 km boulevard along the coast, offering stunning views of the sea, especially at sunset. It's perfect for a leisurely walk or just sitting by the bay, soaking in the city's vibes.",
          },
          {
            key:"Chhatrapati Shivaji Maharaj Terminus (CST)",
            value:"This UNESCO World Heritage site is a striking example of Victorian Gothic architecture and one of Mumbai's busiest railway stations.",
          },
          {
            key:"Elephanta Caves",
            value:"A short ferry ride from Mumbai, these ancient rock-cut caves are dedicated to Lord Shiva and are another UNESCO World Heritage site, showcasing intricate sculptures and carvings.",
          },
          {
            key:"Bandra-Worli Sea Link",
            value:"This architectural marvel connects Bandra and Worli. Driving across this cable-stayed bridge offers spectacular views of the Arabian Sea and the city skyline",
          }]
        }
      ]
    },
    {
      id:3,
      trip:'Trip To Pune',
      imageUrl:"/city/trip.jpg",
      description:"Pune, often referred to as the 'Oxford of the East' for its educational institutions, is a vibrant city in Maharashtra that blends rich history with modernity. Known for its cultural heritage, pleasant weather, and proximity to scenic spots, Pune is a perfect destination for both leisure and business travelers.",
      data:[
        {
          title:'Best Time to Visit',
          description:'The best time to visit Pune is between October and February, when the weather is cool and pleasant. Monsoon (June to September) is also a good time for nature lovers, as the city and nearby areas become lush and scenic.',
          objData:[]
        },{
          title:'Best in Pune',
          description:'Best Places to visit in Pune',
          objData:[
          {
            key:"Shaniwar Wada",
            value:"This historic fortification was the seat of the Peshwas during the Maratha Empire. The impressive structure, though partially destroyed by fire, still stands as a symbol of Pune's royal heritage. Don't miss the light and sound show in the evening that recounts its history.",
          },
          {
            key:"Dagdusheth Halwai Ganpati Temple",
            value:"One of the most famous and revered temples in Pune, dedicated to Lord Ganesha. It's a must-visit, especially during the Ganesh Chaturthi festival when the temple is beautifully decorated and thousands of devotees come to pay their respects.",
          },{
            key:"Sinhagad Fort",
            value:"Located about 30 km from Pune, this fort is a favorite for trekkers and history enthusiasts. It offers a glimpse into Maratha history and breathtaking views of the surrounding valleys. The fort is also known for its connection to Chhatrapati Shivaji Maharaj's legendary general, Tanaji Malusare.",
          },
          {
            key:"Khadakwasla Dam and Lake",
            value:"A popular picnic spot near Pune, the dam provides water to the city and offers a scenic escape with lush greenery and cool breezes. The area is great for a leisurely drive and short getaways.",
          }]
        }
      ]
    },
    {
      id:4,
      trip:'Trip To Sambhaji Nagar',
      imageUrl:"/city/trip.jpg",
      description:"Sambhaji Nagar (formerly known as Aurangabad) is a historically significant city in Maharashtra, known for its rich cultural heritage, historical monuments, and proximity to UNESCO World Heritage sites. Named after the Maratha king Sambhaji Maharaj, the city offers a blend of Mughal architecture, Maratha legacy, and vibrant local culture.",
      data:[
        {
          title:'Best Time to Visit',
          description:'The best time to visit Sambhaji Nagar is between October and March, when the weather is cooler and more pleasant. Avoid the summer months (April to June) as the city can get quite hot, though monsoon (July to September) brings lush greenery to the surrounding areas.',
          objData:[]
        },{
          title:'Best in Sambhaji Naga',
          description:'Best Places to visit in Sambhaji Nagar',
          objData:[
          {
            key:"Ajanta Caves",
            value:"A UNESCO World Heritage Site, the Ajanta Caves are located about 100 km from Sambhaji Nagar. These ancient rock-cut caves date back to the 2nd century BCE and feature stunning Buddhist frescoes, paintings, and sculptures depicting Jataka tales.",
          },
          {
            key:"Ellora Caves",
            value:"Another UNESCO World Heritage Site, the Ellora Caves are just 30 km from the city. These caves represent the three major religions—Buddhism, Hinduism, and Jainism. The most famous cave is the Kailasa temple, which is the largest monolithic rock excavation in the world.",
          },{
            key:"Devgiri Fort",
            value:"Located about 15 km from the city, this medieval fort is known for its architectural brilliance and defensive structures. Once considered one of the most impregnable forts in India, it offers panoramic views and a deep dive into history.",
          },
          {
            key:"Grishneshwar Temple",
            value:"Located near Ellora Caves, this temple is one of the 12 Jyotirlingas dedicated to Lord Shiva. It's an important pilgrimage site for Hindus and features beautiful architectural elements.",
          },
          {
            key:"Siddharth Garden and Zoo",
            value:"A popular spot for families, this garden and zoo is located in the heart of the city. It's a peaceful spot for relaxation with a variety of animals and a well-maintained green space.",
          },
          {
            key:"Panchakki (Water Mill)",
            value:"An engineering marvel from the Mughal era, this water mill uses an underground water channel to power a grinding mill. It was built to generate energy for pilgrims and is surrounded by lush gardens and fountains.",
          },
          {
            key:"Bibi Ka Maqbara",
            value:"Known as the 'Taj of the Deccan,' this stunning structure resembles the famous Taj Mahal and was built by Aurangzeb's son, Azam Shah, in memory of his mother. The monument's intricate architecture and serene surroundings make it a must-visit.",
          }
        ]
        }
      ]
    },
    {
      id:5,
      trip:'Trip To Nashik',
      imageUrl:"/city/trip.jpg",
      description:"Nashik, located in Maharashtra, is a city of immense cultural and historical significance. Known for its ancient temples, scenic vineyards, and proximity to holy sites, Nashik is one of the most popular pilgrimage and tourist destinations in India. It is also one of the major cities where the Kumbh Mela is held every 12 years.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Nashik is between October and March, when the weather is cool and comfortable for exploring. Monsoon (June to September) is also a good time for nature lovers, as the region's waterfalls and greenery come alive, though it can be wet and humid. Avoid the hot summer months (April to June).",
          objData:[]
        },{
          title:'Best in Nashik',
          description:'Best Places to visit in Nashik',
          objData:[
          {
            key:"Trimbakeshwar Temple",
            value:"Located about 30 km from Nashik, this ancient temple is dedicated to Lord Shiva and is one of the 12 Jyotirlingas in India. The temple is known for its unique architecture, with the lingam representing Brahma, Vishnu, and Mahesh (Shiva). Surrounded by the lush greenery of the Brahmagiri hills, it's also the origin of the sacred Godavari River.",
          },
          {
            key:"Panchavati",
            value:"A holy site for Hindus, Panchavati is associated with the exile of Lord Rama, Sita, and Lakshman from the epic Ramayana. It is home to several temples, including Kalaram Temple, Sita Gufa, and Kapaleshwar Temple, all located near the Godavari River.",
          },{
            key:"Saptashrungi",
            value:"A revered shrine dedicated to Goddess Saptashrungi, this temple is located about 60 km from Nashik on a hill surrounded by seven peaks. It's a popular pilgrimage site, offering not only spiritual solace but also spectacular views of the surrounding landscapes.",
          },
          {
            key:"Anjneri Hill",
            value:"Believed to be the birthplace of Lord Hanuman, Anjneri Hill is a significant religious site and a beautiful trekking destination. The trek is moderate and rewards visitors with panoramic views of the valley and a peaceful atmosphere at the top.",
          },
          {
            key:"Ramkund",
            value:"Located in the heart of Nashik, this sacred tank is where pilgrims perform rituals for their ancestors. During the Kumbh Mela, it is one of the most important spots for the holy dip in the Godavari River. Ramkund is surrounded by several temples and ghats, making it a key spiritual location in the city.",
          },
          {
            key:"Dugarwadi Waterfall",
            value:"A hidden gem located about 30 km from Nashik, Dugarwadi is a stunning waterfall, especially during the monsoon season. It is one of the most scenic waterfalls in the region and is ideal for nature lovers and trekkers.",
          },
          {
            key:"Sula Vineyards",
            value:"Nashik is the wine capital of India, and a visit to Sula Vineyards is a must for wine enthusiasts. You can take a tour of the vineyard, learn about the winemaking process, enjoy wine tasting, and relax in the scenic surroundings. The vineyard also hosts the popular SulaFest, a music and wine festival held annually.",
          }
        ]
        }
      ]
    },
    {
      id:6,
      trip:'Trip To Mahableswar',
      imageUrl:"/city/trip.jpg",
      description:"Mahabaleshwar, a serene hill station located in the Western Ghats of Maharashtra, is known for its breathtaking views, lush greenery, cascading waterfalls, and pleasant weather. With its strawberry farms, misty hills, and historical significance, Mahabaleshwar is a perfect getaway for nature lovers, honeymooners, and adventure seekers.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Mahabaleshwar is from October to June, with March to May being perfect for those who want to enjoy strawberries and other fruit products at their peak season. The weather is pleasant, making it ideal for sightseeing and outdoor activities. Mahabaleshwar transforms into a lush paradise during the monsoon with numerous waterfalls and greenery all around, though heavy rains may restrict outdoor activities",
          objData:[]
        },{
          title:'Best in Mahableswar',
          description:'Best Places to visit in Mahableswar',
          objData:[
          {
            key:"Venna Lake",
            value:"One of the most popular spots in Mahabaleshwar, Venna Lake is a scenic spot surrounded by lush greenery. You can enjoy boating on the lake, horse riding along its banks, or simply relax by the water. The serene atmosphere makes it a perfect place to spend time with family and friends.",
          },
          {
            key:"Pratapgad Fort",
            value:"Located about 20 km from Mahabaleshwar, this fort is an important historical site associated with Chhatrapati Shivaji Maharaj. The fort offers stunning views of the Sahyadri Mountains and is a great spot for history buffs and trekkers. Explore the ancient architecture and learn about the battles that took place here.",
          },{
            key:"Elephant's Head Point",
            value:"This viewpoint gets its name from its resemblance to an elephant's head. Offering stunning views of the Sahyadri range and the valleys below, it's a great spot for photography and enjoying the cool mountain breeze.",
          },
          {
            key:"Mapro Garden",
            value:"Famous for its strawberries and other fruit-based products, Mapro Garden is a must-visit spot. You can take a tour of the garden, enjoy fresh strawberries and cream, and buy fruit jams, syrups, and chocolates. The garden also has a café where you can try delicious strawberry shakes and pizzas.",
          },
          {
            key:"Sunrise Point",
            value:"The highest point in Mahabaleshwar, Wilson Point is known for offering spectacular views of the sunrise. From here, you can also see the valleys and hills in all directions. It's a perfect spot for early risers who want to start their day with beautiful natural scenery.",
          },
          {
            key:"Lingmala Waterfall",
            value:"Located near the main town, Lingmala Waterfall is a picturesque spot surrounded by lush greenery. The waterfall cascades from a height of around 600 feet, offering a mesmerizing view. There are viewpoints for both the small and main falls, and the area is perfect for picnics.",
          },
          {
            key:"Panchgani",
            value:"Located just 19 km from Mahabaleshwar, Panchgani is another beautiful hill station known for its strawberry farms and viewpoints. The Table Land, one of the largest plateaus in Asia, offers stunning views and is a popular spot for horse riding, paragliding, and other adventure activities.",
          }
        ]
        }
      ]
    },
    {
      id:7,
      trip:'Trip To Goa',
      imageUrl:"/city/trip.jpg",
      description:"Goa, India's smallest state, is renowned for its stunning beaches, vibrant nightlife, rich history, and diverse culture. Whether you're seeking relaxation, adventure, or a taste of local life, Goa has something to offer for every traveler.",
      data:[
        {
          title:'Best Time to Visit',
          description:"",
          objData:[
            {
              key:"November to February",
              value:"The best time to visit Goa when the weather is pleasant and ideal for beach activities and outdoor exploration. This is also peak tourist season, so expect crowds."
            },
            {
              key:"March to May",
              value:"Warmer temperatures, but still good for beach visits and water sports. It can get quite hot."
            },
            {
              key:"June to September",
              value:"Monsoon season brings heavy rainfall. While the landscape becomes lush and green, many beach shacks close down, and outdoor activities may be limited."
            }
          ]
        },{
          title:'Best in Goa',
          description:'Best Places to visit in Goa',
          objData:[
          {
            key:"Baga Beach",
            value:"Famous for its vibrant nightlife, water sports, and beach shacks. Perfect for partying and socializing.",
          },
          {
            key:"Anjuna Beach",
            value:"Known for its laid-back vibe, flea markets, and lively atmosphere. Great for music lovers and backpackers.",
          },{
            key:"Calangute Beach",
            value:"One of the busiest beaches, ideal for sunbathing and water activities.",
          },
          {
            key:"Palolem Beach",
            value:"A picturesque beach with a tranquil atmosphere, perfect for relaxation and kayaking.",
          },
          {
            key:"Fort Aguada",
            value:"A well-preserved 17th-century Portuguese fort overlooking the Arabian Sea, it features a lighthouse and offers panoramic views of the coastline. It's a great spot for history buffs and photography enthusiasts.",
          },
          {
            key:"Basilica of Bom Jesus",
            value:"A UNESCO World Heritage Site, this baroque church is famous for housing the mortal remains of St. Francis Xavier. The stunning architecture and historical significance make it a must-visit.",
          },
          {
            key:"Dudhsagar Waterfalls",
            value:"Located on the border of Goa and Karnataka, these stunning waterfalls are a popular trekking and jeep safari destination. The sight of cascading water amid lush greenery is breathtaking.",
          },
          {
            key:"Ancestral Goa",
            value:"Located in Loutolim, this cultural museum showcases traditional Goan life and architecture. It's a great place to learn about the state's heritage and enjoy local art.",
          },
          {
            key:"Nightlife",
            value:"Goa is famous for its nightlife, with numerous beach clubs, bars, and nightclubs. Popular spots include Tito's in Baga, Club Cubana in Arpora, and Hilltop in Vagator.",
          }
        ]
        }
      ]
    },
    {
      id:8,
      trip:'Trip To Malvan',
      imageUrl:"/city/trip.jpg",
      description:"Malvan, a picturesque coastal town in Maharashtra, is known for its stunning beaches, rich marine life, and vibrant culture. Famous for its Malvani cuisine and historical forts, Malvan is a perfect getaway for beach lovers and adventure enthusiasts.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Malvan is between October and February when the weather is pleasant and perfect for beach activities and sightseeing. The monsoon season (June to September) can bring heavy rainfall, though the landscape becomes lush and green.",
          objData:[
          ]
        },{
          title:'Best in Malvan',
          description:'Best Places to visit in Malvan',
          objData:[
          {
            key:"Tarkarli Beach",
            value:"Renowned for its pristine white sand and clear blue waters, Tarkarli Beach is perfect for swimming, sunbathing, and water sports. The beach offers activities like parasailing, jet skiing, and banana boat rides, making it a popular spot for adventure seekers.",
          },
          {
            key:"Devbagh Beach",
            value:"Located near the mouth of the Karli River, Devbagh Beach is known for its serene surroundings and tranquil atmosphere. It's ideal for those looking to relax away from the crowds and enjoy beautiful sunsets.",
          },{
            key:"Sindhudurg Fort",
            value:"This historic fort, built by Chhatrapati Shivaji Maharaj in the 17th century, is located on an island just off the coast of Malvan. The fort offers stunning views of the Arabian Sea and has a rich history, with ancient temples and cannons. You can reach the fort by a short boat ride.",
          },
          {
            key:"Malvan Marine Sanctuary",
            value:"This marine sanctuary is known for its rich biodiversity and is a great spot for snorkeling and scuba diving. Explore colorful coral reefs, various fish species, and other marine life in this protected area.",
          },
          {
            key:"Chivla Beach",
            value:"Known for its calm waters and scenic beauty, Chivla Beach is perfect for families. You can enjoy swimming, beach games, and relaxing on the sand.",
          }
        ]
        }
      ]
    },
    {
      id:9,
      trip:'Trip To Ganpatipule',
      imageUrl:"/city/trip.jpg",
      description:"Ganpatipule, a beautiful coastal town in Maharashtra, is known for its pristine beaches, lush greenery, and the famous Ganapati temple. It's a popular pilgrimage site as well as a serene beach destination, making it ideal for relaxation and spiritual experiences.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Ganpatipule is from October to February, when the weather is pleasant and ideal for beach activities. The monsoon season (June to September) brings heavy rainfall, transforming the landscape into a lush green paradise but may restrict outdoor activities.",
          objData:[
          ]
        },{
          title:'Best in Ganpatipule',
          description:'Best Places to visit in Ganpatipule',
          objData:[
          {
            key:"Ganpatipule Beach",
            value:"The main attraction, Ganpatipule Beach, is known for its clean sandy shores and calm waters. It's perfect for swimming, sunbathing, and leisurely walks along the coast. The beach is relatively less crowded, providing a peaceful atmosphere.",
          },
          {
            key:"Swayambhu Ganapati Temple",
            value:"This ancient temple is dedicated to Lord Ganesha and is one of the most revered temples in the region. The idol is said to be self-manifested (Swayambhu), and the temple's scenic location near the beach makes it a significant spiritual site.",
          },{
            key:"Malgund Beach",
            value:"Just a short distance from Ganpatipule, Malgund Beach is quieter and less commercialized, making it a perfect spot for relaxation. The beach is also known for its historical significance as the birthplace of renowned Marathi poet Kavi Keshavsoot.",
          },
          {
            key:"Ganpatipule Lighthouse",
            value:"The lighthouse offers panoramic views of the Arabian Sea and the surrounding coastline. It's a great spot for photography and appreciating the natural beauty of the area.",
          },
          {
            key:"Panchgani Hill Station",
            value:"Located about 45 km from Ganpatipule, Panchgani is known for its scenic viewpoints and pleasant climate. You can visit Table Land, one of the largest plateaus in Asia, and enjoy adventure activities like paragliding.",
          },
          {
            key:"Dapoli",
            value:"A short drive from Ganpatipule, Dapoli offers beautiful beaches, historical forts, and lush coconut groves. It's a great place to explore the coastal culture and enjoy water sports.",
          },
          {
            key:"Rajiv Gandhi Marine National Park",
            value:"Located near Ratnagiri, this national park is known for its rich marine biodiversity. You can take a boat ride to explore the park and enjoy dolphin watching.",
          }
        ]
        }
      ]
    },
    {
      id:10,
      trip:'Trip To Alibag',
      imageUrl:"/city/trip.jpg",
      description:"Ganpatipule, a beautiful coastal town in Maharashtra, is known for its pristine beaches, lush greenery, and the famous Ganapati temple. It's a popular pilgrimage site as well as a serene beach destination, making it ideal for relaxation and spiritual experiences.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Alibag is from November to February, when the weather is cool and pleasant, ideal for beach activities and sightseeing. The monsoon season (June to September) brings heavy rainfall, which may limit outdoor activities but turns the region into a lush green paradise.",
          objData:[
          ]
        },{
          title:'Best in Alibag',
          description:'Best Places to visit in Alibag',
          objData:[
          {
            key:"Alibag Beach",
            value:"The main attraction of the town, Alibag Beach, offers a peaceful environment with calm waters and scenic views. The beach is perfect for evening walks, watching sunsets, and enjoying the fresh sea breeze. You can also spot the Kolaba Fort from the shore.",
          },
          {
            key:"Kolaba Fort",
            value:"Located a short distance offshore from Alibag Beach, Kolaba Fort can be reached by walking during low tide or by a boat ride during high tide. This historic fort, built by Chhatrapati Shivaji Maharaj, offers stunning views of the sea and the surrounding coastline. It's known for its old cannons, temples, and architecture.",
          },{
            key:"Kashid Beach",
            value:"A 30 km drive from Alibag, Kashid Beach is famous for its white sand, crystal-clear waters, and serene surroundings. It's an excellent spot for water sports such as jet skiing, parasailing, and surfing. The beach is less crowded than Alibag Beach, making it perfect for relaxation.",
          },
          {
            key:"Nagaon Beach",
            value:"Nagaon Beach, just 9 km from Alibag, is a popular destination for families and adventure seekers. It offers water sports like banana boat rides, jet skiing, and bumper rides. The beach is known for its coconut and betel nut trees lining the shore, adding to its charm.",
          },
          {
            key:"Murud-Janjira Fort",
            value:"A little farther from Alibag, Murud-Janjira is one of the strongest marine forts in India, located on an island off the coast of Murud. The fort has never been conquered, and its history and architecture are fascinating to explore. You can reach the fort via boat rides from the Murud beach.",
          },
          {
            key:"Kanakeshwar Devasthan Temple",
            value:"Located atop a hill, about 13 km from Alibag, Kanakeshwar Temple is dedicated to Lord Shiva. The temple is surrounded by dense forests, and the trek to the top offers beautiful views of the surrounding area. The climb involves around 700 steps, making it a good trek for nature lovers.",
          }
        ]
        }
      ]
    },
    {
      id:11,
      trip:'Trip To Panchgani',
      imageUrl:"/city/trip.jpg",
      description:"Panchgani, a charming hill station located in the Western Ghats of Maharashtra, is known for its pleasant climate, scenic landscapes, and rich colonial history. Nestled amidst five hills, the town offers a perfect blend of natural beauty and adventure activities, making it a popular destination for nature lovers and thrill-seekers alike.",
      data:[
        {
          title:'Best Time to Visit',
          description:"Panchgani is a year-round destination, but the best time to visit is between September and May. The weather is pleasant and ideal for sightseeing, trekking, and outdoor activities. The monsoon season (June to September) brings heavy rainfall, making the landscape lush and green, though it might restrict outdoor activities.",
          objData:[
          ]
        },{
          title:'Best in Panchgani',
          description:'Best Places to visit in Panchgani',
          objData:[
          {
            key:"Table Land",
            value:"The second-highest plateau in Asia, Table Land offers breathtaking panoramic views of the surrounding hills, valleys, and the town itself. It's an excellent spot for horse riding, paragliding, or just a relaxing walk amidst nature. The plateau also serves as a shooting location for several Bollywood films.",
          },
          {
            key:"Sydney Point",
            value:"Located just 3 km from Panchgani, Sydney Point offers spectacular views of the Dhom Dam, Krishna Valley, and Kamalgad Fort. It's a popular spot for watching sunsets and taking photographs of the scenic landscape.",
          },{
            key:"Dhom Dam",
            value:"About 21 km from Panchgani, Dhom Dam is a scenic spot perfect for a day trip. You can enjoy boating in the serene waters while taking in the beautiful views of the surrounding mountains.",
          },
          {
            key:"Devrai Art Village",
            value:"For art lovers, Devrai Art Village is a must-visit. It's a non-profit organization where you can see local artists creating intricate handicrafts, including brass work, stone carvings, and nature-inspired art. The village promotes sustainable livelihoods and eco-friendly practices.",
          },
          {
            key:"Rajpuri Caves",
            value:"Steeped in mythology, the Rajpuri Caves are believed to be the place where the Pandavas from the Mahabharata took refuge. The caves are surrounded by small water ponds, and visiting the site offers a mix of history and natural beauty.",
          },
          {
            key:"Harrison's Folly",
            value:"This open, flat land offers stunning views of Panchgani and the surrounding region. It's a popular spot for paragliding, and the strong winds make it an ideal place for flying enthusiasts.",
          },
          {
            key:"Kaas Plateau (Valley of Flowers)",
            value:"If you're visiting between August and October, the nearby Kaas Plateau is a must-visit for its stunning display of wildflowers. This UNESCO World Heritage site is home to over 850 species of flowers and is a paradise for nature lovers and photographers.",
          }
        ]
        }
      ]
    },
    {
      id:12,
      trip:'Trip To Shirdi',
      imageUrl:"/city/trip.jpg",
      description:"Shirdi, located in the Ahmednagar district of Maharashtra, is a famous pilgrimage destination, primarily known as the home of Sai Baba, a revered saint who is worshipped by millions. Devotees from all over India and the world visit Shirdi to seek blessings and experience the spiritual aura of the place. Along with its religious significance, Shirdi also offers various attractions and experiences for visitors.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Shirdi is between October and March when the weather is pleasant and ideal for sightseeing. Summers (April to June) can be hot, while the monsoon season (July to September) brings occasional rainfall, which may affect travel plans.",
          objData:[
          ]
        },{
          title:'Best in Shirdi',
          description:'Best Places to visit in Shirdi',
          objData:[
          {
            key:"Sai Baba Samadhi Mandir",
            value:"The main attraction in Shirdi, this temple houses the tomb of Sai Baba, who is believed to have attained Mahasamadhi (salvation) in 1918. The temple is visited by thousands of devotees every day who come to offer prayers, participate in the aartis, and seek blessings. The Darshan (viewing of the idol) is a serene and deeply spiritual experience.",
          },
          {
            key:"Dwarkamai",
            value:"Adjacent to the Samadhi Mandir, Dwarkamai was the mosque where Sai Baba spent a significant part of his life. This place holds a special spiritual significance as it reflects Baba,s teachings and life of simplicity. Devotees can see a stone on which Baba used to sit, and the sacred fire (dhuni) that Baba lit continues to burn even today.",
          },{
            key:"Chavadi",
            value:"Chavadi is another important site where Sai Baba used to rest every alternate night during his later years. The building has been preserved as it was, and a ceremonial procession (Palki) takes place here every Thursday, symbolizing the days when Sai Baba was taken in a palanquin to the Chavadi.",
          },
          {
            key:"Gurusthan",
            value:"Gurusthan, meaning 'place of the Guru,' is where Sai Baba was first spotted meditating under a neem tree when he arrived in Shirdi as a young man. It is believed to be the site where he spent much time meditating. The neem tree still stands, and devotees come here to seek peace and meditate.",
          },
          {
            key:"Shani Shingnapur",
            value:"About 70 km from Shirdi, Shani Shingnapur is another important pilgrimage site dedicated to Lord Shani (the god of Saturn). The village is famous for its belief that houses need no doors because the residents have faith in the deity,s protection. Many visitors to Shirdi make it a point to visit this temple as well.",
          },
          {
            key:"Lendi Baug",
            value:"This garden was watered and maintained by Sai Baba himself, and he spent time meditating here. Lendi Baug houses a lamp lit by Baba, which is still burning today. It,s a peaceful garden where devotees can spend time in quiet reflection.",
          },
          {
            key:"Khandoba Temple",
            value:"Located near the Sai Baba Samadhi Mandir, Khandoba Temple is another important site in Shirdi. It is believed that when Sai Baba first arrived in Shirdi, he was greeted at this temple by the local priest. The temple is dedicated to Lord Khandoba, a form of Lord Shiva.",
          }
        ]
        }
      ]
    },
    {
      id:13,
      trip:'Trip To Beed',
      imageUrl:"/city/trip.jpg",
      description:"Beed, located in the Marathwada region of Maharashtra, is a historically rich and culturally significant district. While Beed may not be as well-known as other tourist destinations, it offers a unique combination of historical sites, temples, and natural beauty, making it worth a visit for those interested in history, architecture, and rural landscapes.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Beed is between August and Jan, when the weather is cool and pleasant, making it easier to explore the outdoor attractions. Summers (April to June) can be extremely hot, with temperatures soaring above 40°C. The monsoon season (July to September) brings relief from the heat and makes the surrounding areas lush and green, especially near waterfalls and dams.",
          objData:[
          ]
        },{
          title:'Best in Beed',
          description:'Best Places to visit in Beed',
          objData:[
          {
            key:"Kankaleshwar Temple",
            value:"One of the most prominent attractions in Beed, Kankaleshwar Temple is dedicated to Lord Shiva. This 12th-century temple, built in the Hemadpanthi style, is located in the middle of a lake. The intricate stone carvings and architectural design make it a significant place for both religious pilgrims and history buffs.",
          },
          {
            key:"Kapildhara Falls",
            value:"Located about 30 km from Beed, Kapildhara Falls is a scenic spot surrounded by lush greenery. The waterfall is especially beautiful during the monsoon season, making it a great place for nature lovers and those seeking a peaceful retreat.",
          },{
            key:"Bendsura Dam",
            value:"Situated around 10 km from Beed, Bendsura Dam is built on the Bendsura River and provides water to the surrounding region. The dam is a popular picnic spot, offering beautiful views of the reservoir and surrounding hills. It,s a great place to relax and enjoy the natural beauty.",
          },
          {
            key:"Vaijnath Jyotirlingas Temple",
            value:"Located about 120 km from Beed, the Parli Vaijnath Temple is one of the twelve Jyotirlingas dedicated to Lord Shiva. This temple is a major pilgrimage site and draws thousands of devotees every year. The temple's ancient architecture and spiritual atmosphere make it a must-visit for those traveling through Beed.",
          },
          {
            key:"Yogeshwari Temple, Ambejogai",
            value:"About 90 km from Beed, the Yogeshwari Temple in Ambejogai is a significant religious site. Dedicated to Goddess Yogeshwari, this temple is one of the Shakti Peethas (sacred places of worship for the goddess Shakti) and attracts devotees from all over the region. The temple's architecture and serene ambiance make it a peaceful place to visit.",
          }
        ]
        }
      ]
    },
    {
      id:14,
      trip:'Trip To Ashtavinayak',
      imageUrl:"/city/trip.jpg",
      description:"The Ashtavinayak Yatra is a highly revered pilgrimage journey in Maharashtra, dedicated to eight forms of Lord Ganesha, the Hindu god of wisdom and prosperity. The eight temples, collectively called 'Ashtavinayak' (Ashta = eight, Vinayak = Ganesha), are spread across different towns and villages in Maharashtra, mainly in the Pune region. Each temple is associated with a unique legend, and the idols in these temples are considered Swayambhu.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The Ashtavinayak Yatra can be done at any time of the year. However, the best time to visit is between October and March, when the weather is cooler and more comfortable for traveling. Avoid visiting during the monsoon season (July to September) due to heavy rains that might make travel difficult, especially in rural areas.",
          objData:[
          ]
        },{
          title:'Ashtavinayak Temples',
          description:'Ashtavinayak Temples',
          objData:[
          {
            key:"Mayureshwar Temple",
            value:" Mayureshwar Temple in Morgaon is the first and most important temple in the Ashtavinayak Yatra, dedicated to Lord Ganesha. Located in the Pune district of Maharashtra, this temple is regarded as the spiritual hub of the eight Ganesha temples in the Ashtavinayak pilgrimage. The temple is associated with rich legends and religious significance, and its peaceful rural setting attracts both devotees and tourists alike. According to ancient scriptures, Lord Ganesha appeared in Morgaon in the form of Mayureshwar, riding a peacock (Mayur). The temple’s mythology tells the story of Lord Ganesha defeating the demon Sindhu, who had obtained a boon that made him nearly invincible. Lord Ganesha, after a fierce battle, destroyed Sindhu to bring peace to the world. After the battle, Ganesha took the form of Mayureshwar.",
          },
          {
            key:"Siddhivinayak Temple (Siddhatek)",
            value:"The Siddhivinayak Temple is one of the eight revered Ashtavinayak Temples of Maharashtra, located in the village of Siddhatek on the banks of the Bhima River in the Ahmednagar district. Dedicated to Lord Ganesha in his form as Siddhivinayak, this temple is believed to have the power to grant success (Siddhi) and fulfill the wishes of devotees. It is a significant destination for pilgrims, especially for those seeking the blessings of Lord Ganesha to overcome obstacles and achieve success.",
          },{
            key:"Ballaleshwar Temple (Pali)",
            value:"Ballaleshwar Temple, located in Pali, Maharashtra, is one of the eight temples in the Ashtavinayak Yatra dedicated to Lord Ganesha. Ballaleshwar is the only Ganesha temple known to be named after a devotee, Ballal, a young boy whose unwavering devotion to Ganesha is the foundation of the temple's legend. This temple holds a unique place in the hearts of Ganesha devotees due to its association with this story of pure faith and the blessings bestowed by Lord Ganesha.",
          },
          {
            key:"Varadvinayak Temple (Mahad)",
            value:"Varadvinayak Temple, located in Mahad, Maharashtra, is one of the eight revered temples of the Ashtavinayak Yatra, dedicated to Lord Ganesha. The name 'Varadvinayak' means the Ganesha who grants boons, making this temple a symbol of divine blessings and fulfillment of desires for devotees. The temple is situated in a scenic setting in Raigad district, and it is known for its serene atmosphere and the unique rituals associated with it.",
          },
          {
            key:"Chintamani Temple (Theur)",
            value:"The Chintamani Temple in Theur, near Pune, Maharashtra, is one of the eight sacred temples in the Ashtavinayak Yatra, dedicated to Lord Ganesha. The deity here is revered as Chintamani, the remover of worries (chinta), and is associated with peace and contentment. This temple holds significant spiritual importance and is visited by thousands of devotees, especially those seeking relief from life's burdens and challenges.",
          },
          {
            key:"Girijatmaj Temple (Lenyadri)",
            value:"The Girijatmaj Temple, located in Lenyadri, Maharashtra, is one of the eight revered temples in the Ashtavinayak Yatra, dedicated to Lord Ganesha. Nestled in the scenic Western Ghats, this temple is unique not only for its divine significance but also for its stunning location and historical architecture.",
          },
          {
            key:"Vighneshwar Temple (Ozar)",
            value:"The Vighneshwar Temple, located in Ozar, Maharashtra, is one of the eight sacred temples in the Ashtavinayak Yatra dedicated to Lord Ganesha. This temple is particularly revered for its unique idol and the significance it holds in the lives of devotees seeking protection from obstacles and difficulties.",
          },
          {
            key:"Mahaganapati Temple (Ranjangaon)",
            value:"The Mahaganapati Temple, located in Ranjangaon, Maharashtra, is one of the eight sacred temples in the Ashtavinayak Yatra, dedicated to Lord Ganesha. This temple is particularly revered and holds a significant place in the hearts of devotees due to the unique attributes of its deity and its rich spiritual heritage.",
          }
        ]
        }
      ]
    },
    {
      id:15,
      trip:'Trip To Bhimashankar',
      imageUrl:"/city/trip.jpg",
      description:"Bhimashankar, located in the Western Ghats of Maharashtra, is a popular pilgrimage destination and a nature lover's paradise. It is renowned for the Bhimashankar Temple, one of the twelve Jyotirlingas (sacred shrines of Lord Shiva) in India. The region is also known for its lush forests, wildlife, and scenic trekking routes, making it a great spot for both spiritual seekers and adventure enthusiasts.",
      data:[
        {
          title:'Best Time to Visit',
          description:"The best time to visit Bhimashankar is between October and March when the weather is pleasant and ideal for outdoor activities and temple visits. The monsoon season (June to September) is also beautiful, but heavy rains can make travel challenging, and trekking trails may become slippery.",
          objData:[
          ]
        },{
          title:'Places To Visit In Bhimashankar',
          description:'Places to Visit',
          objData:[
          {
            key:"Bhimashankar Temple",
            value:"The Bhimashankar Temple is dedicated to Lord Shiva and is an important pilgrimage site for devotees. The temple's architecture showcases beautiful carvings and intricate designs, reflecting the craftsmanship of the time.",
          },
          {
            key:"Wildlife Sanctuary",
            value:"Bhimashankar Wildlife Sanctuary is a UNESCO World Heritage Site and a biodiversity hotspot. The sanctuary is home to a variety of flora and fauna, including the Indian Giant Squirrel, leopards, and numerous species of birds.",
          },{
            key:"Bhimashankar Trek",
            value:"The trek to Bhimashankar is popular among adventure enthusiasts. The trek begins from Pune and takes you through beautiful landscapes, dense forests, and picturesque views.",
          },
          {
            key:"Hanuman Lake",
            value:"Located near the Bhimashankar Temple, Hanuman Lake is a serene spot ideal for relaxation and picnicking. The lake is surrounded by lush greenery and offers a peaceful atmosphere.",
          },
          {
            key:"Narsingh Wadi",
            value:"A small village located on the way to Bhimashankar, Narsingh Wadi is known for its natural beauty and traditional lifestyle. It offers a glimpse into rural life and the scenic surroundings of the Western Ghats.",
          }
        ]
        }
      ]
    }
  ]
  const { title,id } = useParams();
  return (
    <>
      <section className="about-page">
        <HeroPages name="Trips" />
        <div className="container">
          <div className="about-main">
            
            <div className="about-main__text">
              {trips.map((trip, index) => (
              
                trip.id==id?<><div key={index}>
                  <img
                    style={{width:"100%"}}
                    src={trip.imageUrl}
                    alt="car-renting"
                  />
                <h3>
                  {trip.trip}
                </h3>
                {
                
                  trip.data.map((tripdata, dataid) => (
                    
                    <div key={dataid}>
                      <div style={{fontSize:18,marginBottom:"15px",marginTop:"10px"}}>
                        <b >{tripdata.title}</b>
                      </div>
                      <div style={{fontSize:16,marginBottom:"15px",marginTop:"10px"}}>
                        {trip.description}
                      </div>
                      {tripdata.objData.length>0?<>
                      {
                        tripdata.objData.map((KeysData,index2)=>(
                          <div key={index2} style={{fontSize:16,marginTop:"10px"}}><b>{KeysData.key}:</b>{KeysData.value}</div>
                        ))
                      }</>:null}
                    </div>
                      
                    
                  ))
                }</div></>:null
              ))}
              
            </div>
          </div>
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <a href="tel:+91 9324064780">
                <i className="fa-solid fa-phone"></i>
                <h3>+91 9324064780</h3>
              </a>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Trips;
