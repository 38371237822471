import hatchback from "../images/cars/car_swift.png";
import sedan from "../images/cars/sd_car2.png";
import ertiga from "../images/cars/ertiga_new.png";
import innova from "../images/cars/innova_car1.png";
import innovaCrysta from "../images/cars/crysta1.png";
import Passat from "../images/cars-big/passatcc.jpg";

export const CAR_DATA = [
  [
    {
      name: "Hatchbak",
      price: "11/KM",
      img: hatchback,
      capacity: "4+1",
      bags: "3",
      ac: "Yes",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Sedan",
      price: "12/KM",
      img: sedan,
      capacity: "4+1",
      bags: "3",
      ac: "Yes",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Ertiga",
      price: "16/KM",
      img: ertiga,
      capacity: "6+1",
      bags: "3",
      ac: "Yes",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Innova",
      price: "18/KM",
      img: innova,
      capacity: "7+1",
      bags: "3",
      ac: "Yes",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Innova Crysta",
      price: "20/KM",
      img: innovaCrysta,
      capacity: "7+1",
      bags: "3",
      ac: "Yes",
      fuel: "Diesel",
    },
  ],
];
