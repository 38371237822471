function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
              Sai Ram Cab<br/>
              Chanakya Lok Bharti CHS Ltd Marol Maroshi Road Andheri east 400059
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
              <li>
                <a href="tel:+91 9324064780">
                  <i className="fa fa-phone"></i> &nbsp; +91 9324064780
                </a>
              </li>

              <li>
                <a
                  href="mailto: sairamcab4861@gmail.com"
                >
                  <i className="fa fa-envelope"></i>
                  &nbsp; sairamcab4861@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.ellipsesofttech.com/"
                >
                  Design by Ellise Software Technology
                </a>
              </li>
            </ul>
            
            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/termsOfService">Terms & Conditions</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Services</li>
              <li><a href="/Trips/Mumbai-To-Kolhapur/1">Pune To Kolhapur</a></li>
              <li><a href="/Trips/Pune-To-Mumbai/2">Pune To Mumbai</a></li>
              <li><a href="/Trips/Mumbai-To-Sambhaji-Nagar/4">Pune to Sambhaji Nagar</a></li>
              <li><a href="/Trips/Mumbai-To-Nashik/5">Mumbai to Nashik</a></li>
              <li><a href="/Trips/Mumbai-To-Mahableswar/6">Mumbai To Mahableswar</a></li>
              <li><a href="/Trips/Mumbai-To-Shirdi/12">Mumbai To Shirdi</a></li>
              <li><a href="/Trips/Mumbai-To-Panchgani/11">Mumbai To Panchgani</a></li>
              <li><a href="/Trips/Mumbai-To-Goa/7">Mumbai To Goa</a></li>
              <li><a href="/Trips/Mumbai-To-Ashtavinayak/14">Mumbai To Ashtavinayak</a></li>
              <li><a href="#">Mumbai To Grishneshwar</a></li>             
              <li><a href="/Trips/Mumbai-To-Bhimshankar/15">Mumbai To Bhimashanker</a></li>           
              <li><a href="#">Mumbai To Lonvala Khandala</a></li>       
              <li><a href="#">Pune To Mumbai Airport</a></li>
            </ul>

            <ul className="footer-content__2">
              <li>Services</li>
              <li><a href="/Trips/Mumbai-To-pune/3">Mumbai To Pune</a></li>
              <li><a href="/Trips/Mumbai-To-Alibag/12">Mumbai To Alibag</a></li>
              <li><a href="/Trips/Mumbai-To-Malvan/8">Mumbai To Kokan</a></li>
              <li><a href="/Trips/Mumbai-To-Malvan/8">Mumbai To Malvan</a></li>
              <li><a href="/Trips/Mumbai-To-Malvan/8">Mumbai To Sindhudurg</a></li>
              <li><a href="/Trips/Mumbai-To-Ganpatipule/9">Mumbai To Ganpatipule</a></li>
              <li><a href="/Trips/Mumbai-To-Mumbai/2">Mumbai To Mumbai Airport</a></li>
              <li><a href="/Trips/Mumbai-To-pune/3">Mumbai To Pune Airport</a></li>
              <li><a href="/Trips/Mumbai-To-Beed/13">Mumbai To Beed</a></li>
              <li><a href="/Trips/Mumbai-To-Lonavala/">Mumbai To Lonavala Khandal</a></li>
              <li><a href="#">Mumbai To Nanded</a></li>
              <li><a href="#">Mumbai To Parbhani</a></li>
              <li><a href="#">Mumbai To Sangli</a></li>
              <li><a href="#">Mumbai To Audha Nagnath</a></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
