
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  const bookBtn = () => {
    document
      .querySelector("#booking-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <img className="bg-shape" src={BgShape} alt="bg-shape" />
          <div className="hero-content">
            <div className="hero-content__text">
              <h4>Plan your trip now</h4>
              <h1>
                Save <span>big</span> with our Sairam Cab
              </h1>
              <p>
                Rent the car of your dreams. Unbeatable prices, unlimited miles,
                flexible pick-up options and much more.
              </p>
              <div className="hero-content__text__btns">
                
                  <a className="hero-content__text__btns__book-ride" href="tel:+91 9324064780">
                  Book Ride Now&nbsp; <i className="fa fa-circle-check"></i>
                  </a>
                
              </div>
            </div>

            {/* img */}
            <img
              src={HeroCar}
              alt="car-img"
              className="hero-content__car-img"
            />
          </div>
        </div>

        {/* page up */}
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <i className="fa fa-angle-up"></i>
        </div>
        <div style={{position:'fixed',bottom:'100px',right:'20px',zIndex:5}}>
        <div className='col-md-4 col-sm-12 call-btn-rounded ' style={{position:'absolute',bottom:'70px'}}>
          <span className='blinkbtn'>
            <a href="https://api.whatsapp.com/send?phone=919324064780" ><i className="fa fa-whatsapp top-icon" style={{fontSize:'20px'}} aria-hidden="true"> </i></a>
          </span>
        </div>
              <div className='col-md-4 col-sm-12 call-btn-rounded blinkbtn' style={{position:'absolute',bottom:'20px'}}> 
          <span className='blinkbtn'>
            <a href="tel:+91 9324064780">		
              <i className="fa fa-phone top-icon" style={{fontSize:'20px'}} aria-hidden="true"></i>
            </a>
          </span>
              </div>
      </div>
      </section>
    </>
  );
}

export default Hero;
